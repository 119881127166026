import React, { useEffect } from 'react';

const Play = () => {
  useEffect(() => {
    console.log('Play component mounted');
    
    // Try method 1
    try {
      console.log('Attempting window.top redirect');
      window.top.location.href = 'https://lockit.playafi.com';
    } catch (e) {
      console.log('window.top failed:', e);
      
      // Try method 2
      try {
        console.log('Attempting window.open');
        window.open('https://lockit.playafi.com', '_top');
      } catch (e) {
        console.log('window.open failed:', e);
        
        // Final fallback
        console.log('Attempting location.href');
        window.location.href = 'https://lockit.playafi.com';
      }
    }
  }, []);

  return (
    <div style={{ 
      height: '100vh', 
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      background: '#000',
      zIndex: 9999
    }}>
      Redirecting...
    </div>
  );
};

export default Play; 